import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { createPaymentSetupIntent } from './generated-types/createPaymentSetupIntent';
import { useStripe } from '@stripe/react-stripe-js';
import { attachPaymentMethod } from './generated-types/attachPaymentMethod';
import { Token } from '@stripe/stripe-js';

export const CREATE_SETUP_INTENT_MUTATION = gql`
  mutation createPaymentSetupIntent($personId: ID!) {
    createPaymentSetupIntent(personId: $personId) {
      setupIntentClientSecret
    }
  }
`;

export const ATTACH_PAYMENT_METHOD_MUTATION = gql`
  mutation attachPaymentMethod($paymentMethodId: String!, $personId: ID!) {
    attachPaymentMethod(paymentMethodId: $paymentMethodId, personId: $personId)
  }
`;

export const useRegisterCard = (): ((
  stripeToken: Token,
  personId: string
) => Promise<boolean>) => {
  const [createSetupIntent] = useMutation<createPaymentSetupIntent>(
    CREATE_SETUP_INTENT_MUTATION
  );
  const stripe = useStripe();
  const attachPaymentMethod = useAttachPaymentMethod();

  const setupIntent = useCallback(async (personId: string) => {
    try {
      const { data } = await createSetupIntent({
        variables: { personId }
      });
      if (data?.createPaymentSetupIntent) {
        const { setupIntentClientSecret } = data.createPaymentSetupIntent;
        return setupIntentClientSecret;
      }
      return;
    } catch (err) {
      return;
    }
  }, []);

  const createPaymentMethod = useCallback(
    async (stripeToken: Token, clientSecret: string) => {
      if (!clientSecret) {
        return;
      }

      const stripeCardSetup = await stripe?.confirmCardSetup(clientSecret, {
        payment_method: {
          card: { token: stripeToken.id }
        }
      });

      if (stripeCardSetup?.setupIntent) {
        const { payment_method } = stripeCardSetup?.setupIntent;
        return payment_method;
      }
      return;
    },
    [stripe]
  );

  return useCallback(
    async (stripeToken: Token, personId: string) => {
      const clientSecret = await setupIntent(personId);
      if (clientSecret) {
        const paymentMethodId = await createPaymentMethod(
          stripeToken,
          clientSecret
        );
        if (paymentMethodId) {
          return attachPaymentMethod(paymentMethodId as string, personId);
        }
        return false;
      }
      return false;
    },
    [setupIntent, createPaymentMethod, attachPaymentMethod]
  );
};

export const useAttachPaymentMethod = (): ((
  paymentMethodId: string,
  personId: string
) => Promise<boolean>) => {
  const [attachPayment] = useMutation<attachPaymentMethod>(
    ATTACH_PAYMENT_METHOD_MUTATION
  );

  return useCallback(async (paymentMethodId: string, personId: string) => {
    try {
      const { data } = await attachPayment({
        variables: {
          personId,
          paymentMethodId
        }
      });
      return !!data?.attachPaymentMethod;
    } catch (err) {
      return false;
    }
  }, []);
};
