import { Stripe, StripeCardElement, TokenResult } from '@stripe/stripe-js';

type TCreateStripeToken = {
  (stripe: Stripe, cardElement?: StripeCardElement | null): Promise<
    TokenResult
  >;
};

export default function useCreateStripeToken(): TCreateStripeToken {
  return async (stripe, cardElement) => {
    if (!cardElement) {
      throw new Error('No card element provided');
    }
    return await stripe.createToken(cardElement);
  };
}
