import { useState, useCallback, useEffect } from 'react';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { STRIPE_KEY } from '../constants/env';

let stripePromise: Promise<Stripe | null>;
const getStripe = (): typeof stripePromise => {
  if (!stripePromise) {
    stripePromise = loadStripe(STRIPE_KEY);
  }
  return stripePromise;
};

const useStripe = (): Stripe | null => {
  const [stripe, setStripe] = useState<Stripe | null>(null);

  const fetchStripe = useCallback(async () => {
    const stripeInstance = await getStripe();
    setStripe(stripeInstance);
  }, []);

  useEffect(() => {
    fetchStripe().finally();
  }, [fetchStripe]);

  return stripe;
};

export default useStripe;
