import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { fontSources } from '@parsleyhealth/cilantro-ui';
import useStripe from '../../state/use-stripe';

const StripeWrapper: React.FC = props => {
  const stripe = useStripe();

  if (stripe) {
    return (
      <Elements
        stripe={stripe}
        options={{
          fonts: [
            {
              family: 'EuclidFlex',
              src: `url(${fontSources.euclidCircularBRegular}) format('woff')`,
              weight: '400',
              style: 'normal'
            }
          ]
        }}
      >
        {props.children}
      </Elements>
    );
  } else {
    return null;
  }
};

export default StripeWrapper;
